'use strict';

class Cart {

    refreshCart() {

        let req = sf.ajax({
            dst: '/cart/content',
            method: 'GET',
            callback: (res) => {

                let data = JSON.parse(res.responseText);
                sf.alert(data.notify);

                if (data.status == 'ok') {

                    sf('#cart_content').each((elem) => {
                        elem.innerHTML = data.content;
                    });

                    if (miniCart) miniCart.refresh();

                }

            },
            fallBack: () => {

                sf.alert('Ошибка соединения.', 'err');

            },

        });

        req.send();

    }

    removeItem(id) {

        let fd = new FormData;
        fd.append('_method', 'DELETE');

        let req = sf.ajax({
            dst: `/cart/remove-item/${id}`,
            method: 'POST',
            callback: (res) => {

                console.log(res);
                let data = JSON.parse(res.responseText);
                sf.alert(data.notify);

                if (data.status == 'ok') {
                    this.refreshCart();
                }

            },
            fallBack: () => {

                sf.alert('Проблема с подключением.', 'err');

            }

        });

        req.send(fd);

    }

    setCount(id, count = 1) {

        let fd = new FormData;
        fd.append('_method', 'PUT');
        fd.append('count', count);
        let req = sf.ajax({
            dst: `/cart/set-count/${id}`,
            method: 'POST',
            callback: (res) => {

                console.log(res);
                let data = JSON.parse(res.responseText);
                sf.alert(data.notify);

                if (data.status == 'ok') {
                    this.refreshCart();
                }

            },
            fallBack: () => {

                sf.alert('Проблема с подключением.', 'err');

            }

        });

        req.send(fd);

    }

    incCount(id) {

        let fd = new FormData;
        fd.append('_method', 'PUT');

        let req = sf.ajax({
            dst: `/cart/inc-count/${id}`,
            method: 'POST',
            callback: (res) => {

                console.log(res);
                let data = JSON.parse(res.responseText);
                sf.alert(data.notify);

                if (data.status == 'ok') {
                    this.refreshCart();
                }

            },
            fallBack: () => {

                sf.alert('Проблема с подключением.', 'err');

            }

        });

        req.send(fd);

    }

    decCount(id) {

        let fd = new FormData;
        fd.append('_method', 'PUT');

        let req = sf.ajax({
            dst: `/cart/dec-count/${id}`,
            method: 'POST',
            callback: (res) => {

                console.log(res);
                let data = JSON.parse(res.responseText);
                sf.alert(data.notify);

                if (data.status == 'ok') {
                    this.refreshCart();
                }

            },
            fallBack: () => {

                sf.alert('Проблема с подключением.', 'err');

            }

        });

        req.send(fd);

    }

}

const cart = new Cart;
document.addEventListener("DOMContentLoaded", cart.refreshCart());
